export const teamMates
 = [
  {
    name: "Aditya Patel",
    image: process.env.PUBLIC_URL + "/images/Adi.png",
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Amanda Pfiel",
    image: process.env.PUBLIC_URL + '/images/Amanda_2.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Alexia Leclercq",
    image: process.env.PUBLIC_URL + "/images/alexia.png",
    title: "Staff",
    bio: ""
  },
  {
    name: "Alka Tiwari",
    image: process.env.PUBLIC_URL + '/images/Alka.png',
    title: "Postdoctoral Researcher",
    bio: "I am a postdoc, working as a part of The Extreme weather and Urban Sustainability (TExUS) Lab. I have received my PhD from Purdue University advised by Dr. Keith Cherkauer and mentored by Dr. Frank Marks, Dr. Wen-wen Tung and Dr. Dev Niyogi as my committee members. I was advised by Dr. Shivam Tripathi for my Master's in Civil Engineering, majoring in Hydraulics and Water Resources Engineering at IIT Kanpur.Academically, I have spent about two years teaching as Assitant Professor atthe School of Civil Engineering, KIIT University and being a Project Scientist at Interdisciplinary Centre for Water Research (ICWaR) -IISc Bangalore."
  },
  {
    name: "Allysa Dallmann",
    image:process.env.PUBLIC_URL +"/images/Allysa.png",
    title: "Staff",
    bio: ""
  },
  {
    name: "Ali Mousavinezhad ",
    image: process.env.PUBLIC_URL + "/images/Ali_Mousavinezhad_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Arya Chavoshi",
    image: process.env.PUBLIC_URL + '/images/Arya.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Caleb Adams",
    image: process.env.PUBLIC_URL + '/images/Caleb.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Dariya Fallon",
    image: process.env.PUBLIC_URL + "/images/defaultpic.png",
    title: "Staff",
    bio: ""
  },
  {
    name: "Dev Niyogi",
    image: process.env.PUBLIC_URL + "/images/Dev-Pic-Aug2024.jpg",
    title: "Principal Investigator (PI)",
    bio: ""
  },
  {
    name: "Harsh Kamath",
    image: process.env.PUBLIC_URL + '/images/Harsh.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Hassan Dashtian",
    image:process.env.PUBLIC_URL +"/images/Hassan.jpeg",
    title: "Research Assistant Professor",
    bio: ""
  },
  {
    name: "Koorosh Azizi",
    image: process.env.PUBLIC_URL + "/images/Koorosh_2.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Manmeet Singh",
    image: process.env.PUBLIC_URL + "/images/manmeet.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Naveen Sudharshan",
    image: process.env.PUBLIC_URL +"/images/naveen.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Parika Grover",
    image:process.env.PUBLIC_URL +"/images/Parika.jpg",
    title: "Staff",
    bio: ""
  },
  {
    name: "Sasanka Talukdar",
    image: process.env.PUBLIC_URL + '/images/Sasanka.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Shyama Mohanty",
    image: process.env.PUBLIC_URL + "/images/Shyama_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Ting-Yu Dai",
    image: process.env.PUBLIC_URL + '/images/Ting-Yu.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Trevor Brooks",
    image: process.env.PUBLIC_URL + '/images/Trevor.png',
    title: "Graduate Student",
    bio: ""
  },   
  {
    name: "Xinxin Sui",
    image: process.env.PUBLIC_URL + '/images/Sui_2.png',
    title: "Graduate Student",
    bio: ""
  },
  // Add more postdoc members here
];