import React from 'react';
import './PeopleDetails.css';
import People from './pages/People';

const PeopleDetails = ({ person, peopleProjects }) => {
  if (!person) {
    return <div>Person not found.</div>;
  }

  const formattedName = person.name.replace(/\s+/g, '_').toLowerCase();

  console.log('peopleProjects:', peopleProjects[formattedName]);


  return (
    <div className="main-page">
      <div class="peopledetailbox">
        <div class="">
          <img src={person.image} alt={person.name} />
          </div>
          <div>
            <h2>{person.name}</h2>
            <p>{person.title}</p>
            </div>
            </div>
      <div className="associated-projects">
        <div class="intro-content">
        <h2>{person.bio}</h2>
        </div>
        <br/>
        {peopleProjects[formattedName] ? (
          <>
            <h3>Projects:</h3>
            <ul className="projects-content">
            {peopleProjects[formattedName] &&
            peopleProjects[formattedName].map((project) => (
              <li key={project.targetSection}>
                <a href={`/content/${project.targetSection}`}>{project.title}</a>
              </li>
            ))}
        </ul></>
      ) : (
       ""
      )}
      
      </div>
    </div>
  );
};

export default PeopleDetails;