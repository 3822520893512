import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        <div className="footer-logo">
          <Link to="/">
          <img src= {process.env.PUBLIC_URL + '/images/newLogo.svg'}  width= "70px" alt="Logo" className="logo-image2" />
          </Link> <h5> TE(x)US L&Delta;B</h5>
          </div> <br/>
          <Link to="/" className="navbar-logo">
          <img src= {process.env.PUBLIC_URL + '/images/jsg_white_digital.png'}  width= "240px" alt="Logo" className="logo-image" />
          </Link>
        </p>


      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/projects/OngoingProjects'>Research</Link>
            <Link to='/News'>News</Link>
            <Link to='/People'>People</Link>
            <Link to='/Publications'>Publications</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='mailto:dev.niyogi@jsg.utexas.edu'>Email</Link>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.linkedin.com/in/texus/'>Linkedin</Link>
            <Link to='https://scholar.google.com/citations?user=Ixl0-pAAAAAJ&hl=en'>Google Scholar</Link>

          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>TExUS © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;