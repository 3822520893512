import React from 'react';
import './ResearchCards.css';
import ScrollButton from './ScrollButton';
import { Button } from './Button';
import { Link } from 'react-router-dom';


function ResearchCard({ title, pictureSrc, content, buttonText, linkTo, targetSection }) {

  console.log("PictureSec" + pictureSrc)

  const handleClick = () => {
    const section = document.getElementById(targetSection);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (

    <div className="cards">
      <Link to={`/content/${targetSection}`} className="card-link">
      <div>
      <img src={process.env.PUBLIC_URL+ "/images/" +  pictureSrc} alt={title} className="card-picture" />
      </div>
      <h2 className="card-title">{title}</h2>
      <p className="card-content">{content}</p>
          </Link>
       {/*<ScrollButton buttonText={buttonText || "Know More"} targetSection={targetSection} />*/}
    </div>
  );
}

export default ResearchCard;