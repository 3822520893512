import React, { useEffect, useState } from 'react';
import ResearchCardGrid from '../ResearchCardGrid';
import Footer from '../Footer';
import Content from '../Content';
import './OngoingProjects.css';
import { Link } from 'react-router-dom';
import yaml from 'js-yaml'; // Import js-yaml library

function OngoingProjects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Specify the path to your YAML file
    const yamlFilePath = process.env.PUBLIC_URL + '/Projects.yaml';

    // Use fetch to load the YAML file
    fetch(process.env.PUBLIC_URL + '/Projects.yaml')
      .then((response) => response.text())
      .then((yamlData) => {
        // Parse the YAML data into an array of objects
        const parsedProjects = yaml.load(yamlData); // Use loadAll to parse multiple documents

        setProjects(parsedProjects);
      })
      .catch((error) => {
        console.error('Error fetching or parsing YAML file:', error);
      });
  }, []);

  return (
    <>
    <div style={{background:"#f7f7f7"}}>
    <div className="header-banner">
    <div className="wall-container">
        <video className="bg-video" autoPlay muted loop  playsInline>
          <source src={process.env.PUBLIC_URL + '/videos/drone-video.mp4'} />
        </video>
        </div>
         <h1>Ongoing Projects</h1>
    </div>
      <div className="cardsgrid">
        <ResearchCardGrid cards={projects} />
      </div>
      </div>

       {/* 
    This is a multi-line comment.
    <p>You can comment out elements like this, too.</p>
  

      <div className="ongoing-projects-page">
        {projects.map((project, index) => (
          <div key={index}>
            <Content
              title={project.title}
              pictureSrc={project.pictureSrc}
              content={project.longContent}
              targetSection={project.targetSection}
            />
            <Link to={`/content/${project.targetSection}`}>Read More</Link>
          </div>
        ))}
      </div>
      */}

      <Footer />
    </>
  );
}

export default OngoingProjects;