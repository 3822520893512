import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "./components/pages/Home";
import React, { useEffect, useState } from "react";
import Hurricane from "./components/pages/Hurricane";
import HurricaneDebby from "./components/pages/HurricaneDebby";
import People from "./components/pages/People";
import AUDT from "./components/pages/AUDT";
import OngoingProjects from "./components/pages/OngoingProjects";
import { navLinks } from "./components/navData";
import ContentPage from "./components/ContentPage";
import yaml from "js-yaml"; // Import js-yaml library
import PeopleDetails from "./components/PeopleDetails";
import {
  teamMates,
} from "./components/teamData"; // Import your data
import Publications from "./components/pages/Publications";
import News from "./components/pages/News";
import Join from "./components/pages/Join";
import GeoHealth from "./components/pages/GeoHealth";
import CoLab from "./components/pages/CoLab";
import ParisOlympic from "./components/pages/ParisOlymipicRDP";
import ParisOlympic26Jul from "./components/pages/ParisOlympic26Jul";
import ParisOlympic29Jul from "./components/pages/ParisOlymipic29Jul";
import ParisOlympic30Jul from "./components/pages/ParisOlympic30Jul";
import ParisOlympic31Jul from "./components/pages/ParisOlympics31Jul";
import ParisOlympics01Aug from "./components/pages/ParisOlympics1Aug";
import ParisOlympics02Aug from "./components/pages/ParisOlympics02Aug";
import ParisOlympics03Aug from "./components/pages/ParisOlympics03Aug";
import ParisOlympics04Aug from "./components/pages/ParisOlympics04Aug";
import ParisOlympics05Aug from "./components/pages/ParisOlympics5Aug";
import ParisOlympics06Aug from "./components/pages/ParisOlympics06Aug";
import ParisOlympics07Aug from "./components/pages/ParisOlympics07Aug";
import ParisOlympics08Aug from "./components/pages/ParisOlympics08Aug";
import ParisOlympics09Aug from "./components/pages/ParisOlympics09Aug";
import ParisOlympics10Aug from "./components/pages/ParisOlympics10Aug";









export const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  const [projects, setProjects] = useState([]);
  const [peopleProjects, setPeopleProjects] = useState({});

  

  useEffect(() => {
    // Fetch the YAML data from your file
    fetch(process.env.PUBLIC_URL + "/Projects.yaml")
      .then((response) => response.text())
      .then((yamlData) => {
        // Parse the YAML data into an array of objects
        const parsedProjects = yaml.load(yamlData);

        setProjects(parsedProjects);

        // Create a data structure that associates people with projects
        const peopleProjectsData = {};
        parsedProjects.forEach((project) => {
          const collaborators = project.Collaborators.split(",").map((name) =>
            name.trim()
          );
          collaborators.forEach((collaborator) => {
            // Replace spaces in names with underscores and convert to lowercase
            const formattedName = collaborator
              .replace(/\s+/g, "_")
              .toLowerCase();
            if (!peopleProjectsData[formattedName]) {
              peopleProjectsData[formattedName] = [];
            }
            peopleProjectsData[formattedName].push(project);
          });
        });
        setPeopleProjects(peopleProjectsData);
      })
      .catch((error) => {
        console.error("Error fetching or parsing YAML file:", error);
      });

    console.log(peopleProjects);
  }, []);

  return (
    <>
      <Router>
        <Navbar navLinks={navLinks} />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/lab-website-v2" exact element={<Home />} />
          <Route path="/geohealth" element={<GeoHealth />} />
          <Route path="/colab" element={<CoLab />} />
          <Route path="/colab/" element={<CoLab />} />
          <Route path="/audt" element={<AUDT />} />
          <Route path="/hurricane" element={<Hurricane />} />
          <Route path="/hurricanedebby" element={<HurricaneDebby />} />
          <Route path="/ParisOlympics" element={<ParisOlympic />} />
          <Route path="/ParisOlympic" element={<ParisOlympic />} />
          <Route path="/ParisOlympics26Jul" element={<ParisOlympic26Jul />} />
          <Route path="/ParisOlympics29Jul" element={<ParisOlympic29Jul />} />
          <Route path="/ParisOlympics30Jul" element={<ParisOlympic30Jul />} />
          <Route path="/ParisOlympics31Jul" element={<ParisOlympic31Jul />} />
          <Route path="/ParisOlympics01Aug" element={<ParisOlympics01Aug />} />
          <Route path="/ParisOlympics02Aug" element={<ParisOlympics02Aug />} />
          <Route path="/ParisOlympics03Aug" element={<ParisOlympics03Aug />} />
          <Route path="/ParisOlympics04Aug" element={<ParisOlympics04Aug />} />
          <Route path="/ParisOlympics05Aug" element={<ParisOlympics05Aug />} />
          <Route path="/ParisOlympics06Aug" element={<ParisOlympics06Aug />} />
          <Route path="/ParisOlympics07Aug" element={<ParisOlympics07Aug />} />
          <Route path="/ParisOlympics08Aug" element={<ParisOlympics08Aug />} />
          <Route path="/ParisOlympics09Aug" element={<ParisOlympics09Aug />} />
          <Route path="/ParisOlympics10Aug" element={<ParisOlympics10Aug />} />






          <Route
            path="/projects/OngoingProjects"
            element={<OngoingProjects />}
          />
          <Route path="/publications" element={<Publications />} />
          <Route path="/News" element={<News />} />

          {projects.map((project, index) => {
            console.log(`Generating route for ${project.targetSection}`);
            return (
              <Route
                key={index}
                path={`/content/${project.targetSection}`}
                element={<ContentPage project={project} />}
              />
            );
          })}

          <Route path="/People" element={<People />} />
          {[
            ...teamMates
          ].map((person, index) => (
            <Route
              key={index}
              path={`/people/${person.name.replace(/\s+/g, "_").toLowerCase()}`}
              element={
                <PeopleDetails
                  person={person}
                  peopleProjects={peopleProjects}
                />
              }
            />
          ))}
          <Route path="/join" element={<Join />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
