import React from 'react';
import './People.css';
import {teamMates } from '../teamData';

const People = () => {
  const renderTeamMembers = (members) => {
    return members.map((member, index) => (
      <div className="team-member" key={index}>
        <img src={member.image} alt={`Member ${index}`} />
        <p>{member.name}</p>
        {member.title && <p2>{member.title}</p2>}
      </div>
    ));
  };

  return (
      <div>
      <div className="header">
        <div className="wall-container">
          <video className="bg-video" autoPlay muted loop  playsInline>
            <source src={process.env.PUBLIC_URL + '/videos/Austin-2.mp4'} />
            </video>
      </div>
      <h1>Meet the Team</h1>
      </div>

      <div className="meet-the-team">
      <section className="team-section">
      
      <div className="team-members">
      {renderTeamMembers(teamMates)}
      </div>
      
      </section>
      </div>
      
      </div>      
      );     
      };     

export default People;