export const navLinks = [
  {
    label: "Hurricane Experimental Forecast",
    path: "/hurricane",
    sublinks: [],
  },
  // {
  //   label: "Hurricane Debby",
  //   path: "/hurricanedebby",
  //   sublinks: [],
  // },
  {
    label: "Paris Olympic RDP",
    path: "/ParisOlympics",
    sublinks: [],
  },
  // {
  //   label: "HOME",
  //   path: "/",
  //   sublinks: [],
  // },
  {
    label: "Research",
    path: "/projects/OngoingProjects",
    sublinks: [
      // {
      //   label: "Ongoing Projects",
      //   path: "/projects/OngoingProjects",
      //   /* innerSublinks: [
      //     {
      //       label: "Cool Pavement",
      //       path: "/projects/OngoingProjects/",
      //     },
      //     {
      //       label: "Community Heat Mapping",
      //       path: "/projects/extreme-heat/community-heat-mapping",
      //     },
      //     // Add more sublinks as needed
      //   ], */
      // },
      // {
      //   label: "Completed Projects",
      //   path: "/projects/OngoingProjects",
      //  /* innerSublinks: [
      //     {
      //       label: "Project 2 Detail 1",
      //       path: "/projects/precipitation/detail1",
      //     },
      //     {
      //       label: "Project 2 Detail 2",
      //       path: "/projects/precipitation/detail2",
      //     },
      //     // Add more sublinks as needed
      //   ],*/
      // },

      // Add more top-level project links as needed
    ],
  },
  {
    label: "News",
    path: "/News",
    sublinks: [],
  },
  {
    label: "People",
    path: "/people",
    sublinks: [],
  },
  {
    label: "Publications",
    path: "https://scholar.google.com/citations?hl=en&user=Ixl0-pAAAAAJ&view_op=list_works&sortby=pubdate",
    sublinks: [],
  },
  // {
  //   label: "JOIN US",
  //   path: "/join",
  //   sublinks: [],
  // },
];